<template>
  <div>
    <ed-table
    :key="$root.$session.versao"
       strModel="Cadastro/Exame"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @search="getFuncao"
    />
  </div>
</template>

<script>
import { EdTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable },
  mounted() {
    this.getFuncao();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      formFiltros:{},
      arrayHeaders: [
        {
          text: "Empresa",
          sortable: true,
          value: "strEmpresa",
        },
        {
          text: "Funcionário",
          sortable: true,
          value: "strFuncionario",
        },
        {
          text: "Tipo de Absenteísmo",
          sortable: true,
          value: "strTipoAbsenteismo",
        },
      ],
      arrayRows: [],
      objPagination: null,
    };
  },
  methods: {
    onRegister() {
      this.$router.push({ name: "absenteismo.register" });
    },

    onEdit(data) {
      this.$router.push({
        name: "absenteismo.edit",
        params: { intId: data.intId },
      });
    },

    getFuncao() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request.get("Absenteismo/Absenteismo", this.formFiltros).then((objResult) => {
        if (objResult.status == 200) {
          this.objPagination = objResult.result;
          this.loading = false;

          let arrayRows = [];

          objResult.result.data.forEach((objModel) => {

            let strTipo = '<span >';
            let strEmpresa = '<span>';
            let strFuncionario = '<span>'

            //TIPO CAT
            if(objModel.tipo_cat){
              strTipo += '<label class="ed-full-width"><b>Tipo:</b> '+ objModel.tipo_cat.strNome +'</label>';
            }

            if(objModel.tipo_acidente){
              strTipo += '<label class="ed-full-width"><b>Tipo Acidente:</b> '+ objModel.tipo_acidente.strNome +'</label>';
            }

            strTipo += '</span>';


            //EMPRESA
            if(objModel.unidade){
              strEmpresa += '<label class="ed-full-width"><b>Unidade:</b> '+ objModel.unidade.strNome +'</label>';
            }

            strEmpresa += '</span>';

            //FUNCIONARIO
            if(objModel.setor){
              strFuncionario += '<label class="ed-full-width"><b>Setor:</b> '+ objModel.setor.strNome +'</label>';
            }

            if(objModel.funcao){
              strFuncionario += '<label class="ed-full-width"><b>Função:</b> '+ objModel.funcao.strNome +'</label>';
            }

            strFuncionario += '</span>';

            let item = {
              intId: objModel.intId,
              strEmpresa: objModel.empresa.strNome+strEmpresa,
              strFuncionario: objModel.funcionario.strNome+strFuncionario,
              strTipoAbsenteismo: objModel.tipo_absenteismo.strNome+strTipo,
              _item: objModel,
            };

            arrayRows.push(item);
          });

          this.arrayRows = arrayRows;
        }
      });
    },
  },
};
</script>
